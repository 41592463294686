import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import appleFavicon from '~assets/favicon/apple-touch-icon.png';
import favicon32 from '~assets/favicon/favicon-32x32.png';
import favicon16 from '~assets/favicon/favicon-16x16.png';
import SgHeader from '~2-components/SgHeader/SgHeader';
import SgFooter from '~2-components/SgFooter/SgFooter';
import '~styles/styleguide.scss';

const SgLayout = ({ children }) => {
    const metaData = useStaticQuery(
        graphql`
        {
            site {
                siteMetadata {
                    title
                }
            }
        }
        `
    );

    const projectTitle = metaData.site.siteMetadata.title;

    return (
        <>
            <Helmet
                title={projectTitle}
            >
                <link rel="apple-touch-icon" sizes="180x180" href={appleFavicon} />
                <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
                <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
                <link rel="manifest" href="/static/favicon/site.webmanifest" />
            </Helmet>

            <SgHeader />

            <div className="sg-page-content">
                {children}
            </div>

            <SgFooter />
        </>
    );
};

export default SgLayout;

SgLayout.propTypes = {
    children: PropTypes.node.isRequired
};
