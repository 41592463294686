import React from 'react';
import SgLayout from '~layouts/sg-layout';
import { Link, useStaticQuery, graphql } from 'gatsby';
import startCase from 'lodash.startcase';

export function generateSitemap() {
    const allPages = useStaticQuery(
        graphql`
        {
            allFile {
                nodes {
                    name
                    relativeDirectory
                }
            }
            allKontentItemGeneralContent {
                nodes {
                    elements {
                        page_slug {
                            value
                        }
                    }
                    system {
                        name
                    }
                }
            }
            allKontentItemProfile {
                nodes {
                    elements {
                        page_url {
                            value
                        }
                    }
                    system {
                        name
                    }
                }
            }
            allKontentItemNewsArticle {
                nodes {
                    elements {
                        page_url {
                            value
                        }
                    }
                    system {
                        name
                    }
                }
            }
        }
        `
    );

    const sitemap = {
        core: [],
        components: [],
        staticPages: [],
        genContentPages: [],
        profilePages: [],
        newsArticlePages: [],
    };

    allPages.allFile.nodes.map(page => {
        const parentDir = page.relativeDirectory;

        if (parentDir.includes('styleguide')) {
            if (parentDir.includes('core')) {
                sitemap.core.push(page);
            } else if (parentDir.includes('components')) {
                sitemap.components.push(page);
            }
        } else if (page.name != 'index') {
            sitemap.staticPages.push(page);
        }
    });

    allPages.allKontentItemGeneralContent.nodes.map(page => {
        const siteMapPage = {
            name: page.system.name,
            relativeDirectory: page.elements.page_slug.value
        };
        sitemap.genContentPages.push(siteMapPage);
    });

    allPages.allKontentItemProfile.nodes.map(page => {
        const siteMapPage = {
            name: page.system.name,
            relativeDirectory: page.elements.page_url.value
        };
        sitemap.profilePages.push(siteMapPage);
    });

    allPages.allKontentItemNewsArticle.nodes.map(page => {
        const siteMapPage = {
            name: page.system.name,
            relativeDirectory: page.elements.page_url.value
        };
        sitemap.newsArticlePages.push(siteMapPage);
    });

    // Sort everything alphabetically
    Object.keys(sitemap).map(area => {
        sitemap[area].sort(function(a, b) {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
    });

    return sitemap;
}
const Launchpad = () => {
    const sitemap = generateSitemap();

    return (
        <SgLayout>
            <section className="sg-index">
                <div className="container">
                    <div className="grid-wrapper">
                        {Object.keys(sitemap).map(area => {
                            return (
                                <div className={`column ${area === 'newsArticlePages' ? 'news-articles' : ''}`} key={area}>
                                    <h2>{startCase(area)}</h2>
                                    <ul>
                                        {sitemap[area].map(page => {
                                            let url;
                                            if (area !== 'genContentPages' && area !== 'profilePages' && area !== 'newsArticlePages') {
                                                url = `${page.relativeDirectory.length !== 0 ? '/' : ''}${`${page.relativeDirectory}/`}${page.name}/`;
                                            } else if (page.relativeDirectory === '/') {
                                                url = 'homepage';
                                            } else {
                                                url = page.relativeDirectory;
                                            }
                                            return (
                                                <li key={page.name}>
                                                    {area === 'newsArticlePages' &&
                                                        <a href={url} >{startCase(page.name)}</a>
                                                    }
                                                    {area !== 'newsArticlePages' &&
                                                        <Link to={url} >{startCase(page.name)}</Link>
                                                    }
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </SgLayout>
    );
};

export default Launchpad;
