import React from 'react';
import { Link } from 'gatsby';
import favicon from '~assets/favicon/favicon-32x32.png';
import logoMark from '~assets/styleguide/luminary-logo-mark.svg';

const SgHeader = () => {
    return (
        <section className="sg-header">
            <div className="container">
                <div className="logo-type-wrapper">
                    <Link to={`/`} className="client-logo">
                        <img src={favicon} alt="Project Favicon" />
                    </Link>

                    <div className="group-type">
                        <h1>Advice Line Injury Lawyer</h1>
                    </div>
                </div>

                <Link to={`/`} className="logo-mark">
                    <img src={logoMark} alt="Luminary Digital" />
                </Link>
            </div>
        </section>
    );
};

export default SgHeader;
