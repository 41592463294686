import React from 'react';
import logoMark from '~assets/styleguide/luminary-logo-mark.svg';
import { generateSitemap } from '~pages/launchpad';
import { Link } from 'gatsby';
import startCase from 'lodash.startcase';
import { v4 as uuidv4 } from 'uuid';

const SgFooter = () => {
    const sitemap = generateSitemap();

    return (
        <section className="sg-footer">
            <div className="container">
                <div className="grid-wrapper">
                    {Object.keys(sitemap).map(area => {
                        return (
                            <div key={area} className={`column ${area === 'newsArticlePages' ? 'news-articles' : ''}`}>
                                <h2>{startCase(area)}</h2>
                                <ul>
                                    {sitemap[area].map(page => {
                                        let url;
                                        if (area !== 'genContentPages' && area !== 'profilePages' && area !== 'newsArticlePages') {
                                            url = `${page.relativeDirectory.length !== 0 ? '/' : ''}${`${page.relativeDirectory}/`}${page.name}/`;
                                        } else {
                                            url = `/${page.relativeDirectory}`;
                                        }
                                        return (
                                            <li key={uuidv4()}>
                                                {area === 'newsArticlePages' &&
                                                    <a href={url} >{startCase(page.name)}</a>
                                                }
                                                {area !== 'newsArticlePages' &&
                                                    <Link to={url} >{startCase(page.name)}</Link>
                                                }
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    })}
                </div>

                <Link to={`/`} className="logo-mark">
                    <img src={logoMark} alt="Luminary Digital" />
                </Link>
            </div>
        </section>
    );
};

export default SgFooter;
